<template>
  <div>
    <div class="pageHeader" style="overflow: hidden">
      <div style="float:left">
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
      </div>
      <div style="float:right;">
        <el-input placeholder="客户名称" v-model="customerName" class="input-with-select" style="width:350px;"></el-input>&nbsp;
        <el-button type="primary" @click="handle_search()" icon="el-icon-search">搜索</el-button>
      </div>
    </div>

    <el-table :data="page.list" v-loading="showLoading" element-loading-text="拼命加载中" border width="width:100%; height:50%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="name" label="客户名称" width="200"></el-table-column>
      <el-table-column prop="contact" label="联系人" width="100"></el-table-column>
      <el-table-column prop="phone" label="联系电话" width="100"></el-table-column>
      <el-table-column prop="type" label="类型" width="50"></el-table-column>
      <el-table-column prop="level" label="级别" width="50"></el-table-column>
      <el-table-column prop="tag" label="标签" width="50"></el-table-column>
      <el-table-column prop="province" label="省份" width="100"></el-table-column>
      <el-table-column prop="city" label="城市" width="100"></el-table-column>
      <el-table-column prop="area" label="区域" width="100"></el-table-column>
      <el-table-column prop="status" label="状态" width="50"></el-table-column>
      <el-table-column prop="scope" label="操作" min-width="100">
        <template  #default="scope">
          <el-button type="text" icon="el-icon-edit" @click="handle_edit(scope.row)">编辑</el-button>
<!--          <el-button type="text" icon="el-icon-delete" class="red" @click="handle_del(scope.$index, scope.row)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="devlogChange"
                   :current-page="page.pageNum"
                   :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                   :total="page.total">
    </el-pagination>

  </div>
</template>

<script>
import CUS from '../../api/biz/api_customer';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";
import ORDER from "@/api/biz/api_order";
import PM from "@/api/base/api_pm";

export default {
  name: "customerupdatelist",
  components: {
  },
  data() {
    return {
      showLoading: false,
      page: {list: [], pageNum: 1, pageSize: 10, total: 0},
      editTitle: '更新客户数据'
    }
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;

      // 执行查询
      CUS.list({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },

    // 翻页
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    // 编辑
    handle_edit(row) {
      let {...clonerow} = row;
      this.editCustomer = clonerow;
      this.editShow = true;
    },

    // 删除
    handle_del(index, row) {
      this.$confirm('此操作将删除[' + row.name + '] 客户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        CUS.delete({id: row.id}).then((res) => {
          if (200 == res.code) {
            this.$message.success("操作成功！");
            this.initPageData();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(() => {
      });
    }

  }
}
</script>

<style scoped>
  .pageHeader {
    background-color: white;
    border-bottom: 1px solid #CCCCCC;
    font-size: 18px;
    padding: 5px 10px;
  }

  .red {
    color: #ff0000;
  }
</style>